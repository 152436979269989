/* TYPO */
@import 'bootstrap';
@font-face {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
}

html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
table,
tr,
td {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.3;
    word-spacing: normal;
    letter-spacing: normal;
    text-decoration: none;
    text-transform: none;
    text-indent: 0ex;
    text-rendering: optimizeLegibility;
}

html {
    font-size: 13px;
}

h1 {
    font-size: 2.4rem;
    font-family: Arial, Verdana, Helvetica, sans-serif;
}

h2 {
    font-size: 1.5rem;
    font-family: Arial, Verdana, Helvetica, sans-serif;
}

h3 {
    font-size: 1.41rem;
    font-family: Arial, Verdana, Helvetica, sans-serif;
}

h4 {
    font-size: 1.2rem;
    font-family: Arial, Verdana, Helvetica, sans-serif;
}

h5 {
    font-size: 1rem;
    font-family: Arial, Verdana, Helvetica, sans-serif;
}

h6 {
    font-size: 0.91rem;
    font-family: Arial, Verdana, Helvetica, sans-serif;
}

p {
    font-size: 1rem;
    margin: 0 0 1.2rem;
}

ol,
ul,
table,
tr,
td {}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small,
small,
.small {
    color: inherit;
}

pre {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    border: 0;
    background: none;
    padding: 0;
}


/* BASIC-ELEMENTS */

a {
    color: #003980;
}

a:hover {
    color: #003980;
    text-decoration: underline;
}

::selection {
    background: #318dfd!important;
    color: white!important;
}

* {
    outline-color: #1ab6f1;
}

input,
select,
textarea,
button,
a.btn {
    outline: none;
}

input[type=checkbox],
input[type=radio] {
    cursor: pointer;
    vertical-align: middle;
}


/* BASE-DESKTOP -------------------------------------------------- */

html,
body {
    background-color: white;
}

.container-fluid > .container {
    padding: 0;
}


/* HEADER */

.pageHeader {
    position: relative;
    height: 270px;
    z-index: 1;
    margin-bottom: 0;
}

.pageHeader > .container-fluid:first-child + .container-fluid {
    background: #2e4680;
    height: 70px;
}

.pageHeader > .container-fluid > .container {
    position: relative;
}

.pageHeader .logo {
    background: url(../Images/Base/logo.png) top center no-repeat;
    text-indent: -9999px;
    overflow: hidden;
    height: 53px;
    display: block;
    float: none;
    margin: 56px 0 93px;
    max-width: 408px;
}

.pageHeader .siteName {
    position: absolute;
    top: 125px;
    left: 75px;
    z-index: 1;
    color: black;
    display: block;
    font-size: 1.063rem;
}

.pageHeader .siteName a,
.pageHeader .siteName a:hover {
    color: black;
    text-decoration: none;
}

.pageHeader .languageSelect {
    position: absolute;
    top: 200px;
    right: -15px;
    z-index: 10;
    background: black;
    width: 100%;
    box-sizing: content-box;
    padding: 0 15px;
}

.pageHeader .languageSelect.overlapped {
    z-index: 1;
}

.pageHeader .languageSelect > div {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 15px 0;
    margin: 0;
    background: black;
    display: none;
}

.pageHeader .languageSelect h2 {
    color: white;
    font-size: 1.3rem;
}

.pageHeader .languageSelect select {
    position: absolute;
    right: 17px;
    top: -48px;
    padding: 5px;
    background: white;
    border: 1px solid white;
    font-size: 1.1em;
    cursor: pointer;
}

.banner {
    margin: 0 -15px 0;
    overflow: hidden;
    position: relative;
    height: 320px;
    display: block;
    border-bottom: 1px solid #2e4680;
    margin-bottom: -1px;
}

.banner img {
    width: 2030px;
    margin: 0 auto;
    float: none;
    position: absolute;
    left: 50%;
    margin-left: -1015px;
}

#Cookies {
    background: #b6d1e4;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    text-align: center;
}

#Cookies p {
    text-align: center;
}

#Cookies p .ui-icon-info {
    display: inline-block;
    margin: 10px 10px 0 0;
}

#Cookies button {
    background: rgba(255, 255, 255, 0.5);
    border: none;
    padding: 5px;
    margin-left: 10px;
}

#Cookies button .ui-icon-close {
    float: right;
}


/* TOPNAVIGATION */

.mainNavigation {
    background: transparent;
    border: none;
    border-radius: 0;
    z-index: 1;
}

.mainNavigation .container-fluid {
    padding: 0;
}

.mainNavigation .navbar-toggle {
    position: absolute;
    top: -97px;
    right: -15px;
    border-radius: 0;
    background: white!important;
    padding: 0 7px;
    height: 34px;
    box-sizing: content-box;
}

.mainNavigation .navbar-collapse {
    z-index: 1;
    background: white;
    border: 0px solid #7f9db9;
}

.mainNavigation ul li {
    font-size: 1.2em;
    font-family: Arial, Verdana, Helvetica, sans-serif;
    line-height: 20px;
}

.mainNavigation ul li a {
    color: black!important;
    display: block;
    position: relative;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    z-index: 100;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.mainNavigation ul li.active {
    background: white!important;
    color: black;
}

.mainNavigation ul li.active a,
.mainNavigation ul li a:hover,
.mainNavigation ul li a:focus {
    background: white!important;
    color: black!important;
}


/* ADMINLOGIN */

.adminLogin {
    position: absolute;
    top: -205px;
    left: -15px;
    width: 100%;
}


/* FOOTER */

.pageFooter {
    font-weight: normal;
}

.pageFooter * {
    color: #a3a3a3!important;
}

.pageFooter a:hover {
    text-decoration: underline;
}

.pageFooter .darkBar {
    background: #212121;
    margin-top: 30px;
}

.pageFooter .darkBar * {
    color: white!important;
}

.pageFooter p {
    margin: 20px 0;
    float: left;
    font-size: 0.92rem;
    padding-left: 15px;
    padding-right: 12px;
}

.pageFooter .navbar-default .navbar-nav>li>a:hover {
    color: #a3a3a3;
}


/* BOTTOMNAVIGATION ---------------------------------------------- */

.bottomNavigation {
    background: transparent!important;
    border: none!important;
    min-height: 0;
    margin-top: 10px;
}

.bottomNavigation ul {
    float: right;
}

.bottomNavigation ul li {
    float: right;
}

.bottomNavigation ul li:first-child {
    margin-left: 51%;
    float: left;
}

.bottomNavigation ul li a {
    padding: 0 10px 0 0;
    text-align: center;
    font-size: 1.2rem;
    border-right: 1px solid black;
    margin-top: 13px;
    height: 16px;
    line-height: 15px;
}

.bottomNavigation ul li a:hover {
    text-decoration: underline;
    color: black!important;
}

.bottomNavigation ul li:first-child + li + li a {
    border: none;
    padding-right: 0;
}


.KxBnBContent td {
    vertical-align: top;
    img {
        max-width: 100%;
    }
}


/* BASE-RESPOSIVE ------------------------------------------------ */

@media all and (min-width: 480px) {
    .pageFooter form {
        max-width: 420px;
    }
}

@media all and (min-width: 768px) {
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
    .pageHeader .languageSelect {
        top: 270px;
        z-index: 2;
    }
    .pageHeader .languageSelect select {
        width: 20px;
    }
    .mainNavigation {
        margin-left: -20px;
    }
    .mainNavigation .navbar-collapse {
        background: transparent;
        border: none;
        padding-left: 0;
        padding-right: 0;
    }
    .mainNavigation ul li a {
        color: white!important;
        padding: 0 17px;
        white-space: pre-line;
        height: 70px;
        text-align: center;
        line-height: 1.4rem;
        display: table-cell;
        vertical-align: middle;
    }
    .banner {
        display: run-in;
        clear: both;
    }
    .pageFooter h2 {
        text-align: left;
    }
    .pageFooter > .container {
        padding: 0;
    }
    .pageFooter form {
        max-width: 460px;
        width: 360px;
        margin-left: 0;
    }
    .pageFooter form input {
        top: 0px;
        left: auto;
        right: 0;
    }
    .pageFooter form input[type="text"] {
        width: 49%;
    }
    .bottomNavigation {
        margin-bottom: 0;
    }
}

@media all and (min-width: 992px) {
    .container-fluid > .container {
        padding: 0 15px;
    }
    .pageHeader .languageSelect select {
        width: auto;
    }
    .pageHeader .siteName {
        left: 90px;
    }
    .mainNavigation {
        margin-left: -27px;
    }
    .mainNavigation ul li a {
        padding-left: 22px;
        padding-right: 22px;
    }
    .banner {
        margin-left: 0;
        margin-right: 0;
    }
    .pageFooter > .container {
        padding: 0 15px;
    }
    .pageFooter form {
        width: 460px;
        min-height: 48px;
    }
    .pageFooter form input[type="text"] {
        width: 60%;
    }
    .bottomNavigation ul li {
        float: left;
    }
    .bottomNavigation ul li:first-child {
        margin-left: 0;
    }
    .bottomNavigation ul li a {
        padding-left: 10px;
    }
}

@media all and (max-width: 991px) {
    .pageHeader .adminLogin {
        top: -150px;
        width: 120px;
        right: -10px;
    }
    .pageHeader .adminLogin .btn {
        margin: 10px 10px -5px 0;
        width: 101px;
        float: right;
    }
}

@media all and (max-width: 767px) {
    body.login form > .nav-bar {
        height: 0!important;
    }
    .pageHeader {
        height: 200px;
    }
    .pageHeader .logo {
        margin-bottom: 45px;
        margin-top: 33px;
        width: 80%;
        background-size: 100% auto;
        background-position: center left;
    }
    .pageHeader .siteName {
        top: 84px;
    }
    .pageHeader .adminLogin {
        top: 20px;
        height: 30px;
        width: 70%;
        left: 0;
        right: auto;
    }
    .pageHeader .adminLogin .btn {
        border-color: #78b0d7;
        background: #78b0d7;
        padding: 4px 5.4%;
        margin: 0px 2px 0 0;
        float: left;
        width: auto;
    }
    .pageHeader .adminLogin .btn:hover {
        border-color: white;
    }
    .pageHeader .adminLogin div.ResourceMode,
    .pageHeader .adminLogin div.ResourceModeActive {
        margin: 0;
        vertical-align: middle;
        padding: 5px 35px 5px 10px;
        border-radius: 0;
    }
    .mainNavigation .navbar-collapse {
        margin-top: 1px;
        border-bottom: 1px solid #2e4680;
        -webkit-box-shadow: 0px 3px 6px 0px rgba(50, 50, 50, 0.3);
        -moz-box-shadow: 0px 3px 6px 0px rgba(50, 50, 50, 0.3);
        box-shadow: 0px 3px 6px 0px rgba(50, 50, 50, 0.3);
    }
    .mainNavigation ul li a:hover {
        color: white!important;
        background: #78b0d7!important;
    }
    .banner {
        display: none;
    }
    .pageFooter * {
        text-align: center;
        float: none!important;
    }
    .pageFooter .darkBar {
        margin-top: 20px;
    }
    .bottomNavigation ul {
        margin-top: 0;
    }
    .pageFooter p.poweredBy {
        margin-top: -19px;
    }
}

@media all and (max-width: 479px) {
    .pageHeader .logo {
        width: 80%;
        background-size: 100% auto;
        background-position: center left;
    }
    .pageHeader .siteName {
        left: 14.5%;
        width: 66%;
    }
    .pageHeader .adminLogin {
        width: 85%;
    }
}

@media print {
    .pageHeader,
    .container-fluid .banner,
    .pageFooter {
        display: none!important;
    }
}
